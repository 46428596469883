export default {
  common: (locale) => {
    switch (locale) {
      case 'sr-Latn':
      case 'sr':
        return import(/* webpackChunkName: "i18n.common.sr-Latn" */ './common.sr-Latn');
      case 'hr':
      case 'hr-Latn':
        return import(/* webpackChunkName: "i18n.common.hr" */ './common.hr');
      case 'ro':
      case 'ro-Latn':
        return import(/* webpackChunkName: "i18n.common.ro" */ './common.ro');
      case 'es':
        return import(/* webpackChunkName: "i18n.common.ro" */ './common.es');
      default:
        return import(/* webpackChunkName: "i18n.common" */ './common');
    }
  },
};
