import { eventBus, busService, betslipUtility, successTicketHandler, errorTicketHandler, ticketCheck } from '@/utility';
import { parseInt, isEqual, isNil } from 'lodash';
import types from './mutationTypes';
import { ticketAPI } from '@/api';

export default {
  async handleParentMessages({ commit, state }, payload) {
    const { action } = payload;
    if (action === 'Slave.Load') {
      const { autoPayout } = payload.data.settings;
      const { device, user } = payload.data;
      commit(types.SET_DEVICE_UUID, device);
      commit(types.SET_AUTO_PAYOUT, autoPayout);
      // FALLBACK FOR CONFIG SERVICE SOMETIMES NOT GETTING USER
      commit(types.SET_USER_TOKEN, user.auth.token);
      state.loadApp = true;
      if (state.configServiceInit && !state.busServiceInit) {
        busService.init();
      }
    } else if (action === 'User.AuthorizationChanged') {
      commit(types.SET_USER_TOKEN, payload.data.token);
    }
  },
  async handleBusMessages({ state, getters, dispatch, commit }, payload) {
    // console.log('handleBusMessages ::: ', payload);
    const { eventName } = payload;
    switch (eventName) {
      // WebSocket game events
      case 'invalid':
      case 'stop':
        dispatch('setConnectionStatus', false);
        break;
      case 'SetIdle':
        // Handle countdown
        dispatch('handleSetIdle', payload);
        break;
      case 'StartEvent':
      case 'SetStep':
      case 'FinishEvent':
        dispatch('handleEvents', payload);
        break;
      case 'TicketUpdate': {
        if (!payload.data.error) {
          ticketCheck.stopTicketChecker(betslipUtility.prepareSevenTicketData(payload.data.ticket));
          const { data } = payload;
          eventBus.$emit('focusBettingInput');
          // TODO: Refactor for serbian taxId which will be on ticket level
          if (data.ticket.bets[0].additionalDetails?.taxId) await dispatch('mapBetGuids', data);
          dispatch('betslip/disablePayinButton', getters.isBettingDisabled, { root: true });

          // Send message over GGateway event 'Tickets.Update' to update state of ticket on platform
          const ticketData = {
            action: state.ticketActionMap[data.action] || data.action, // available values: Add, Cancel, Payout
            ticket: betslipUtility.prepareSevenTicketData(data.ticket),
          };
          // console.log(' ticketData ', ticketData);
          eventBus.$emit('ticketUpdate', ticketData);
          // CHECK IF THE TICKET SHOULD BE PRINTED
          // Rare but possible case can happen that ticket update message is late and auto ticket checker is activated
          // In that case the ticket is already printed and ticket update shouldn't print the ticket.
          // * If ticket check is in progress return and don't print.
          if (getters.ticketChecksInProgress.some((ticket) => ticket.requestUuid === payload.data.ticket.requestId))
            return;
          //* If ticket is already printed by ticket check, remove that ticket from the list and don't print
          if (
            getters.printedTicketsByTicketCheck.some((ticket) => ticket.requestUuid === payload.data.ticket.requestId)
          ) {
            commit('REMOVE_PRINTED_TICKET_BY_TICKET_CHECK', payload.data.ticket.requestId);
            return;
          }
          //* Update printed tickets array and print the ticket
          const ticketObject = {
            id: ticketData.ticket.id,
            requestUuid: ticketData.ticket.requestUuid,
            timestamp: Date.now(),
          };
          await dispatch('updatePrintedTicketsByTicketUpdate', ticketObject);
          eventBus.$emit('printTemplate', ticketData);
        } else {
          errorTicketHandler(payload.data);
          ticketCheck.stopTicketChecker({ requestId: payload.data.requestId });
        }
        break;
      }
      // Gravity Gateway events
      case 'UI.Show': {
        if (payload.data?.name[0] === 'TicketCheck') {
          // IF INPUT HAS FOCUS ON TICKET SCAN THE SCANNER WILL FILL THE TICKET INPUT WITH BARCODE
          eventBus.$emit('resetInput');
        } else {
          eventBus.$emit('focusBettingInput');
        }
        break;
      }
      case 'Slave.Shown':
        eventBus.$emit('focusBettingInput');
        break;
      case 'Master.Event':
        // TODO: Handle keyboard events (or shortcuts) on keydown
        if (payload.event === 'keydown') {
          dispatch('handleKeyEvents', payload);
          eventBus.$emit('ticketPreviewListener', payload);
        }
        break;
      case 'Widget.Event':
        // TODO: Handle keyboard events (or shortcuts) on click
        if (payload.data.event === 'click' || payload.data.shortcut) {
          dispatch('handleKeyEvents', {
            key: payload.data.shortcut,
          });
        }
        break;
      case 'Betslip.Blocked':
        dispatch('betslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'add',
        });
        break;
      case 'Betslip.Unblocked':
        dispatch('betslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'remove',
        });
        break;
      case 'Tickets.Checked':
        // TODO: After 7Shop releases the flag for ticket check strategies, it is necessary to make changes.
        // 'seven' strategy for ticket check
        if (payload.data?.ticket?.bets) {
          dispatch('showTicketPreview', payload.data?.ticket);
        } else {
          // 'slave' (new) strategy for ticket check
          const ticketId = payload.data?.ticket?.id;
          const ticketCheckTicket = await ticketAPI
            .checkTicketBarcode(ticketId)
            .then((response) => response)
            .catch((error) => {
              errorTicketHandler(error);
            });
          // TODO: Temp solution for ticket PIN see above TODO
          // add ticket pin if it exists
          if (isNil(ticketCheckTicket)) return;

          ticketCheckTicket.productTicket.ticketPin = payload.data?.ticket.ticketPin
            ? payload.data?.ticket.ticketPin
            : null;
          if (ticketCheckTicket.status.value === 'WON' && getters.autoPayout) {
            const action = 'Tickets.Payout';
            const ticketData = {
              action,
              data: {
                ticket: betslipUtility.prepareSevenTicketData(ticketCheckTicket.productTicket),
              },
            };
            busService.sendMessageAsync(ticketData.action, ticketData.data);
          } else {
            await dispatch('showTicketPreview', ticketCheckTicket?.productTicket);
            busService.sendMessage('UI.Hide', { name: ['All'] });
          }
        }
        // Checked the ticket and forwards ticket data to product ticket preview (popup)
        break;
      case 'Tickets.GetActions':
        {
          // Handle right-click data on the ticket list
          const { ticket } = payload.data;
          const ticketStatus = ticket.localStatus?.toLowerCase() || ticket?.status?.value?.toLowerCase() || '';

          // Create object that has available list of actions for selected ticket
          const filteredActions = getters.availableTicketActions
            .filter((obj) => (ticketStatus ? obj.status === ticketStatus : obj.action.includes('checkStatus')))
            .flatMap((obj) => obj.action)
            .reduce((acc, action) => {
              return { ...acc, [action]: { active: true } };
            }, {});
          payload.resolve({ actions: filteredActions });
        }
        break;
      case 'Tickets.Payout': {
        // Automatic payout won tickets
        const { ticket } = payload.data;
        const ticketData = {
          action: 'Tickets.Payout',
          data: { ticket },
        };
        busService.sendMessageAsync(ticketData.action, ticketData.data);
        break;
      }
      case 'Tickets.ReBet': {
        const { ticket } = payload.data;
        // Because od different response/payload on 'Tickets.Rebet' (depending is canceled/paidout or accepted ticket)
        // we handle ticket bets data this way (omg)
        const ticketBets = ticket?.bets ?? ticket?.productTicket?.selections;
        eventBus.$emit('resetInput');
        eventBus.$emit('focusBettingInput');
        dispatch('betslip/clearBetslip', true, { root: true });
        dispatch('ticketRebet', ticketBets);
        dispatch('betslip/updateStake', ticket.payin);
        setTimeout(() => {
          eventBus.$emit('focusBetslipFooterInput');
        }, 0);
        break;
      }
      case 'Tickets.ReCheck': {
        const { ticket } = payload.data;
        dispatch('setTicketAction', ticket.action);
        if (ticket.id && ticket.id !== '-') {
          await ticketAPI
            .checkTicketBarcode(ticket.id)
            .then((response) => {
              ticketCheck.stopTicketChecker(payload.ticket);
              // add ticket pin if it exists
              // eslint-disable-next-line no-param-reassign
              response.productTicket.ticketPin = payload.data?.ticket.ticketPin ? payload.data?.ticket.ticketPin : null;
              successTicketHandler(response);
            })
            .catch((error) => {
              errorTicketHandler(error, ticket);
            });
        } else {
          eventBus.$emit('ticketRequestIdCheck', ticket);
        }
        break;
      }
      case 'Tickets.PrintCopy': {
        const { ticket } = payload.data;
        const ticketData = {
          action: ticket.action,
          ticket,
        };
        eventBus.$emit('printTemplate', ticketData);
        break;
      }
      case 'Tickets.PayingSentSuccess':
        eventBus.$emit('resetInput');
        eventBus.$emit('focusBettingInput');
        dispatch('betslip/clearBetslip', true, { root: true });
        dispatch('betslip/disablePayinButton', getters.isBettingDisabled, { root: true });
        // This is currently implemented to clear stake per bet input for crash cash
        // It will need to be adjusted for other games
        dispatch('betslip/updateStake', 0);
        break;
      case 'Tickets.PayingFailed':
        dispatch('betslip/disablePayinButton', getters.isBettingDisabled, { root: true });
        eventBus.$emit('focusBetslipFooterInput');
        break;
      case 'User.AuthorizationChanged':
        commit(types.SET_USER_TOKEN, payload.data.auth.token);
        break;
      default:
    }
  },
  async updatePrintedTicketsByTicketUpdate({ commit, getters }, ticket) {
    const currentTimestamp = Date.now();
    const { ticketsPrintedByTicketUpdate } = getters;
    // CLEAR ALL TICKETS THAT ARE OLDER THAN MINUTE
    const filteredTickets = ticketsPrintedByTicketUpdate.filter((ticket) => {
      return currentTimestamp - ticket.timestamp < 60000;
    });
    commit(types.UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE, filteredTickets);

    // ADD THIS TICKET TO ARRAY OF TICKETS
    commit(types.ADD_PRINTED_TICKET_BY_TICKET_UPDATE, ticket);
  },
  handleSetIdle({ state, commit, dispatch }, payload) {
    const { time, displayEventId, isBettingDisabled } = payload.data;
    // convert a time in milliseconds to seconds
    let eventTime = time >= 1000 ? parseInt(time / 1000, 10) : time;
    commit(types.SET_EVENT_TIME, --eventTime);
    commit(types.SET_EVENT_ID, displayEventId);
    if (!isEqual(displayEventId, state.eventId)) {
      dispatch('updateBetEvent', +displayEventId);
    }
    commit(types.SET_EVENT_NAME, payload.event);
    dispatch('setBettingDisabled', isBettingDisabled);
  },
  handleEvents({ commit, dispatch }, payload) {
    const { displayEventId } = payload.data;
    commit(types.SET_EVENT_ID, displayEventId);
    commit(types.SET_EVENT_NAME, payload.event);
    dispatch('updateBetEvent', parseInt(+displayEventId + 1));
    dispatch('setBettingDisabled', false);
  },
  /*
   * Handle key events (shortcuts)
   */
  handleKeyEvents({ dispatch, getters }, payload) {
    switch (payload.key) {
      case '+':
        // New ticket
        eventBus.$emit('resetInput');
        eventBus.$emit('focusBettingInput');
        break;
      case '/':
        // Reset all
        eventBus.$emit('resetInput');
        eventBus.$emit('focusBettingInput');
        dispatch('betslip/clearBetslip', true, { root: true });
        dispatch('betslip/disablePayinButton', getters.isBettingDisabled, { root: true });
        // Currently implemented to clear stakePerBet on CC
        dispatch('betslip/updateStake', 0);
        break;
      case 'q':
      case 'Q':
        // Print previous results
        eventBus.$emit('getResults');
        break;
      // Focus first bet
      case 'm':
      case 'M':
        if (payload.ctrlKey) eventBus.$emit('focusBet');
        break;
      case 'ctrl + M':
        eventBus.$emit('focusBet');
        break;
      default:
    }
  },
  showTicketPreview({ commit }, payload) {
    if (isNil(payload)) return;
    commit(types.SET_TICKET_PREVIEW_DATA, payload);
    commit(types.SET_TICKET_PREVIEW_STATUS, true);
  },
  hideTicketPreview({ commit }) {
    commit(types.SET_TICKET_PREVIEW_DATA);
    commit(types.SET_TICKET_PREVIEW_STATUS, false);
  },
  /**
   * Show a dialog with an message and optional action (GGateway event)
   */
  sendGGMessage({ commit }, payload) {
    const notification = {
      action: 'Dialog.Show',
      data: {
        action: '7S:Dialog.Show',
        message: payload.message,
        type: payload.type || 'warning',
        delay: payload.delay || 3000,
      },
    };
    commit(types.SEND_GG_MESSAGE, notification);
  },
  closeGGMessage({ commit }) {
    const notification = {
      action: 'Dialog.Close',
      data: {},
    };
    commit(types.SEND_GG_MESSAGE, notification);
  },
  setTicketAction({ commit }, payload) {
    commit(types.SET_TICKET_ACTION, payload);
  },
  updateBetEvent({ getters, dispatch }, payload) {
    const ticket = getters['betslip/tickets'];
    ticket.forEach((bet) => {
      dispatch(
        'betslip/updateTicket',
        {
          bet,
          round: payload,
          displayId: payload,
        },
        { root: true },
      );
    });
  },
  ticketRebet({ dispatch }, payload) {
    payload.forEach((bet) => {
      const data = {
        outcomeId: bet?.selections?.[0]?.outcomeId || bet?.outcomeId,
        payment: bet?.payment.real.value,
      };
      const betData = betslipUtility.createBet(data);
      dispatch('betslip/addBet', betData, { root: true });
    });
  },
  updateOnlineStatus({ commit, state }) {
    const isOnline = navigator.onLine;
    if (isOnline) {
      commit(types.RESET_CONNECTION_LOST_TIME);
    } else if (isNil(state.connectionLostTimestamp)) {
      commit(types.SET_CONNECTION_LOST_TIME);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  mapBetGuids({}, data) {
    const { ticket } = data;
    ticket.bets = ticket.bets.map((bet) => {
      return { ...bet, guid: bet.additionalDetails.taxId };
    });
  },
  setConnectionStatus({ commit }, payload) {
    commit(types.SET_CONNECTION_STATUS, payload);
  },
  setTranslations({ commit }, payload) {
    commit(types.SET_TRANSLATIONS, payload);
  },
  setTicketActionSuccessful({ commit }, payload) {
    commit(types.SET_TICKET_ACTION_SUCCESSFUL, payload);
  },
  setBettingDisabled({ commit }, bettingDisabled) {
    commit(types.SET_BETTING_DISABLED, bettingDisabled);
  },
  updateOddsRules({ commit }, rules) {
    if (!rules.maxPossibleMultiplier) return;
    commit(types.UPDATE_ODDS_RULES, rules.maxPossibleMultiplier.value);
  },
};
