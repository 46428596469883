export default {
  common: {},
  crashcash: {
    development: {
      baseURL: 'https://games-crash-cash-ticket.staging.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-history.staging.de-2.nsoft.cloud',
      gameResults: '/results/game/',
      ticketPayin: '/tickets',
      ticketPayout: '/tickets',
      ticketCancel: '/tickets',
      ticketBarcodeCheck: '/tickets/barcode/',
      ticketRequestIdCheck: '/tickets/',
    },
    staging: {
      baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-history.staging.de-2.nsoft.cloud', // TODO
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
    },
    production: {
      baseURL: 'https://games-aggregator.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-history.de-2.nsoft.cloud', // TODO
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
    },
  },
  gamestest: {
    development: {
      baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-history.staging.de-2.nsoft.cloud', // TODO
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
    },
    staging: {
      baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-history.staging.de-2.nsoft.cloud', // TODO
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
    },
    production: {
      baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-history.staging.de-2.nsoft.cloud', // TODO
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
    },
  },
  virtualsoccerdev: {
    development: {
      baseURL: 'https://games-vsts.dev.de-2.nsoft.cloud',
      pusherKey: 'c197ae95f575bc466e26',
    },
    staging: {
      baseURL: 'https://games-vsts.staging.de-2.nsoft.cloud',
      pusherKey: 'c197ae95f575bc466e26',
    },
    production: {
      baseURL: 'https://games-vsts.de-2.nsoft.cloud',
      pusherKey: '112cd2f19bc6b016084d',
    },
  },
};
