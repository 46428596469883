import { uuid } from 'vue3-uuid';
import { toUpper } from 'lodash';
import store from '@/store';
import { busService } from '@/utility';

/**
 * Prepare bet for betslip (games-sdk)
 * @returns {object} return new bet
 */
const createBet = (data) => {
  const eventId = store.getters.eventInProgress ? +store.state.eventId + 1 : store.state.eventId;
  // In case of ticket rebet
  const payment = data.payment ?? store.getters.minBetAmount;
  const outcome = data.outcomeId ?? data;
  // dummy Bet
  const newBet = {
    key: uuid.v4(),
    displayId: eventId,
    round: eventId,
    market: store.getters.getTranslation('multiplier'),
    betType: 1, // marketId
    outcome: store.getters.getTranslation('crash_odds'),
    // time: '13.04.2021. 14:15',
    id: +outcome,
    odd: +outcome,
    locked: false,
    payment: +payment,
  };
  return newBet;
};
/**
 * Prepare bets for ticket payin action
 * @param bets {Array}
 * @param payin {Number}
 * @returns {Array}
 */
const prepareBets = (bets, payin) => {
  const isPaymentPerBetActive = store.getters['betslip/isPaymentPerBetActive'];
  return bets.map((bet) => {
    const { betType, id } = bet;
    const realPayin = isPaymentPerBetActive ? +bet.payment : +payin;
    return {
      payIn: {
        real: realPayin,
        bonuses: [],
      },
      selections: [
        {
          // eventId: round,
          marketId: betType,
          outcomeId: id.toString(),
        },
      ],
      selectedSystem: 1,
    };
  });
};
/**
 * Prepare metadata for ticket payin action
 * @returns {Object}
 */
const prepareMetadata = (config, requestUuid = '') => {
  const metadata = {
    action: 'PAYIN',
    currency: 'BAM', // ???
    channel: toUpper(config.channelId),
    platform: config.platformName,
    product: config.productName, // CrashCash
    tenantId: config.tenantId,
    tenantName: 'Prva Firma',
    productInstanceId: 'b547a709-cf11-4ea8-a3dd-6f7d87706c16', // for now harcoded
    requestId: requestUuid,
    origins: [], // ??? for retail is operatorUuid
  };
  return metadata;
};
/**
 * Prepares the ticket data
 * @returns {object} return ticket data object
 */
const prepareTicketData = (data = {}) => {
  const { config } = store.getters;
  const { productName } = config;
  const payment = store.getters['betslip/payment'];
  const totalPayment = store.getters['betslip/totalPayment'];
  const tickets = store.getters['betslip/tickets'];
  const bets = prepareBets(tickets, payment);
  const metadata = prepareMetadata(config, data?.requestUuid);
  return {
    payment,
    totalPayment,
    tickets,
    bets,
    metadata,
    productName,
  };
};
/**
 * Create 'product' ticket data for payin action
 * @returns {Object} return new ticket data object
 */
const createProductTicket = (data = {}) => {
  const { bets, metadata } = prepareTicketData(data);
  const ticketData = {
    ticket: { bets },
    metadata,
  };
  return ticketData;
};
/**
 * Create 'seven' ticket data for payin action
 * @returns {Object} return new ticket data object
 */
const createSevenTicket = () => {
  const { bets, totalPayment } = prepareTicketData();

  const ticket = {
    stake: totalPayment,
    bets,
  };
  return ticket;
};
/**
 *
 */
const ticketPayin = async () => {
  const validatePayin = validateBetslip() ?? {};
  if (validatePayin.isValid) {
    store.dispatch('closeGGMessage');
    const ticket = createSevenTicket();

    const ticketPayinData = {
      action: 'Tickets.Pay',
      data: {
        ticket,
      },
    };
    // console.log(' ticketPayinData ', ticketPayinData);
    await busService.sendMessageAsync(ticketPayinData.action, ticketPayinData.data);
  } else {
    if (validatePayin.acceptPayin) {
      // TODO: Show 'accepted' notification/message
    } else {
      store.dispatch('sendGGMessage', { message: validatePayin.message });
    }
  }
};
/**
 * Validate betslip ticket
 * @returns {Object}
 */
const validateBetslip = () => {
  const isTicketValid = store.getters['betslip/isTicketValid']; // check locked bets
  const notification = store.getters['notifications/notification'];
  const { isBettingDisabled, connectionActive } = store.getters;

  if (isBettingDisabled || !connectionActive) {
    return {
      isValid: false,
      message: store.getters.getTranslation('general_betting_disabled'),
      acceptPayin: false,
    };
  }

  if (!isTicketValid) {
    return {
      isValid: false,
      message: 'general_delete_locked_bets',
      acceptPayin: true,
    };
  }

  if (notification?.value) {
    return {
      isValid: false,
      message: notification.value,
      acceptPayin: false,
    };
  }

  return {
    isValid: true,
    message: null,
    acceptPayin: false,
  };
};

/**
 * Prepare an array of bet selections based on the 'selectionRefs'
 * @returns {Array}
 */
const prepareBetSelections = (ticket) => {
  const { selections, bets } = ticket;
  const { config } = store.state;

  bets.forEach((bet) => {
    const betSelections = bet.selectionRefs.map(({ selectionIndex }) => {
      const selection = selections[selectionIndex] || {};
      return selection;
    });
    // eslint-disable-next-line no-param-reassign
    bet.selections = betSelections;
    const maxPossibleWin = bet.winnings.maxPossible;
    bet.cappedWinnings =
      config.rawData.maxPossibleWin < maxPossibleWin ? config.rawData.maxPossibleWin : maxPossibleWin;

    // Bet totals extraction
    bet.betPayment = bet.payment.real.value;
    bet.betTax = bet.taxes.filter((tax) => tax.type === 'PAYIN').reduce((total, tax) => total + tax.value, 0);
    bet.betStake = bet.stake.real.value;
    bet.betWinnings = bet.winnings.total.value;
    bet.maximumWinnings = bet.winnings.maxPossible;
    bet.minimumWinnings = bet.winnings.minPossible;
    bet.betPayout = bet.payout.real.value;
    bet.payoutTax = bet.possiblePayout.taxes.reduce((total, tax) => total + tax.value, 0);
    bet.maximumPayout = bet.maximumPossiblePayout.real.value;
    bet.maximumPayoutTax = bet.maximumPossiblePayout.taxes
      .filter((tax) => tax.type === 'PAYOUT')
      .reduce((total, tax) => total + tax.value, 0);
    bet.minimumPayout = bet.minimumPossiblePayout.real.value;
    bet.minimumPayoutTax = bet.minimumPossiblePayout.taxes
      .filter((tax) => tax.type === 'PAYOUT')
      .reduce((total, tax) => total + tax.value, 0);
  });
  return bets;
};
/**
 * Prepare an object of 'seven' ticket response data
 * @returns {Object}
 */
const prepareSevenTicketData = (data) => {
  // eslint-disable-next-line no-console
  console.log(' prepareSevenTicketData ', data);
  const { config } = store.state;
  const { totals, codes, productName, requestId, createdAt, status, bets } = data;
  const { taxes, payment, payout, stake, maximumPossiblePayout } = totals;
  const payinTaxes = taxes?.filter((tax) => tax.type === 'PAYIN');
  const payinTax = payinTaxes.reduce((sum, tax) => sum + tax.value, 0);
  const payoutTaxes = taxes?.filter((tax) => tax.type === 'PAYOUT');
  const payoutTax = payoutTaxes.reduce((sum, tax) => sum + tax.value, 0);

  const winnings = bets.reduce((totalWinnings, bet) => totalWinnings + (bet.winnings?.total.value || 0), 0);
  const ticketPin = data.ticketPin || data.additionalDetails?.ticketPin || null;

  const luckyMultipliers = data.meta?.luckyMultipliers || null;
  const luckyMultiplier = luckyMultipliers ? JSON.parse(luckyMultipliers)[0]?.luckyMultiplier : null;
  const id = codes?.find((code) => code.type === 'barcode')?.id;

  const maxPossibleWin = totals.winnings.maxPossible || null;
  const minPossibleWin = totals.winnings.minPossible || null;
  const maxPossiblePayout = maximumPossiblePayout.real.value || null;
  const maxPossiblePayoutTax = maximumPossiblePayout.taxes.reduce((acc, tax) => {
    return tax.type === 'PAYOUT' ? acc + tax.value : acc;
  }, 0);
  const minPossiblePayout = totals.minimumPossiblePayout.real.value || null;
  const minPossiblePayoutTax = totals.minimumPossiblePayout.taxes.reduce((acc, tax) => {
    return tax.type === 'PAYOUT' ? acc + tax.value : acc;
  }, 0);
  const cappedWinnings = config.rawData.maxPossibleWin * bets.length;
  const totalCappedWinnings = cappedWinnings < maxPossibleWin ? cappedWinnings : maxPossibleWin;

  const taxData = {
    firstName: data.additionalDetails?.firstName || null,
    lastName: data.additionalDetails?.lastName || null,
    amount: data.additionalDetails?.amount || null,
    isForeignPlayer: data.additionalDetails?.isForeignPlayer || null,
    playerCnp: data.additionalDetails?.playerCnp || null,
  };

  return {
    id,
    paymentId: '',
    product: productName,
    payin: payment.real.value,
    payout: payout.real.value,
    payoutTax,
    payinTax,
    possiblePayout: payout.real.value,
    possiblePayoutTax: undefined,
    requestUuid: requestId,
    createdAt: createdAt,
    stake: stake.real.value,
    status: {
      value: status,
    },
    ticketPin,
    winnings: winnings,
    maxPossibleWin,
    minPossibleWin,
    maxPossiblePayoutTax,
    maxPossiblePayout,
    minPossiblePayout,
    minPossiblePayoutTax,
    luckyMultiplier,
    bets: prepareBetSelections(data),
    isCopy: data.isCopy || '',
    totalCappedWinnings,
    taxData,
  };
};

export default {
  createBet,
  ticketPayin,
  createProductTicket,
  prepareBetSelections,
  prepareSevenTicketData,
};
