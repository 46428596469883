import * as Sentry from '@sentry/vue';

const start = function (app) {
  Sentry.init({
    app,
    environment: process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.15, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
export default { start };
