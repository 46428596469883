<template>
  <div id="app">
    <GameLoader v-if="!isAppLoaded" />
    <TemplateDefault v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '@/utility';
import GameLoader from '@/components/GameLoader';
import TemplateDefault from '@/layouts/TemplateDefault';

export default {
  name: 'App',
  components: {
    GameLoader,
    TemplateDefault,
  },
  computed: {
    ...mapGetters(['isAppLoaded', 'isLongerOffline', 'isBettingDisabled']),
  },
  watch: {
    async isLongerOffline(newValue) {
      if (newValue === true) {
        this.setConnectionStatus(false);
        eventBus.$emit('disableInputs');
      } else {
        this.setConnectionStatus(true);
        eventBus.$emit('focusBettingInput');
      }
    },
    isBettingDisabled(newValue) {
      this.disablePayinButton(newValue);
    },
  },
  mounted() {
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Store: ', this.$store);
    }
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    document.documentElement.setAttribute('theme', 'dark');
  },
  onUnmounted() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    ...mapActions(['updateOnlineStatus', 'setConnectionStatus']),
    ...mapActions('betslip', ['disablePayinButton']),
  },
};
</script>

<style lang="scss">
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: $dark-grey;
  text-align: center;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $font-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
