import { each } from 'lodash';
import state from '../store/state';

const checkValidations = (validations) => {
  const data = {};
  each(validations, (validation) => {
    switch (validation.type) {
      case 'required':
        data.required = true;
        break;
      case 'length':
        data.maxLength = validation.max;
        break;
      case 'range':
        data.minRangeValue = validation.min;
        data.maxRangeValue = validation.max;
        break;
      case 'pattern':
        data.expression = validation.expression;
        break;
      default:
    }
  });
  return data;
};

const validateInput = (value, pattern) => pattern.test(value);
const validateOdd = function (odd) {
  const rules = state.gameConfig.oddRules;
  if (odd < rules.minOdd) {
    return { isValid: false, rule: 'general_rule_minOdds', value: rules.minOdd };
  } else if (odd >= rules.maxOdd) {
    return { isValid: false, rule: 'general_rule_maxOdds', value: rules.maxOdd };
  }
  return {
    isValid: true,
    rule: false,
    value: NaN,
  };
};
export default {
  checkValidations,
  validateInput,
  validateOdd,
};
