import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getApiRoute } from '../../config';
import store from '../store';
// import MockAdapter from 'axios-mock-adapter';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 5000,
});

http.interceptors.request.use((requestConfig) => {
  // standard login for ticket check requests
  const { config } = store.getters;
  const aggregatorURL = getApiRoute('baseURL', config);

  if (aggregatorURL && requestConfig.url.includes(aggregatorURL)) {
    const token = store.getters.token;
    if (token) {
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return requestConfig;
});
// IF you need to mock a response from axios you can use this snippet
// change the status code and the response
// if you enable this than all other requests that you make from the app will throw 404 error
// It can be changed probably in the config of the mock instance

// const mock = new MockAdapter(http);
// mock.onGet('https://games-aggregator.staging.de-3.nsoft.cloud/seven/tickets?cpvUuid=fbc5bb0d-d575-4619-b8be-4e8437b2368c&uuid=cb279b1b-9996-4291-973c-11c17818d583').reply(404, {
//   "ticket": {
//       "bets": [
//           {
//               "payIn": {
//                   "real": 1,
//                   "bonuses": []
//               },
//               "selections": [
//                   {
//                       "marketId": 1,
//                       "outcomeId": "4"
//                   }
//               ],
//               "selectedSystem": 1
//           }
//       ],
//       "product": "CrashCash",
//       "pendingStartedAt": 1697531356806,
//       "statusCheckTimeout": null,
//       "config": {
//           "availableTicketActions": {},
//           "statusConfig": {},
//           "pendingTicketCheckInterval": 10000,
//           "pendingTicketRejectPeriod": 30000,
//           "color": "#178685"
//       },
//       "indexDbfailed": false,
//       "localPin": "",
//       "availableActions": [],
//       "localStatus": "PENDING",
//       "taxVerified": false,
//       "taxData": {},
//       "printTemplate": null,
//       "loyaltyDetails": null,
//       "localRevisions": [],
//       "action": "Add",
//       "requestUuid": "60ac9fc4-018c-4a78-8d4e-2615ba083440",
//       "id": "-",
//       "lang": "bs",
//       "reqUuidCheck": "https://services-staging.7platform.com/shop/tickets/request/",
//       "check": "https://services-staging.7platform.com/shop/tickets/check/",
//       "partialUrl": "CrashCash/check.json",
//       "findBy": "requestUuid",
//       "translation": "Crash Cash",
//       "clientId": 186
//     },
//     "code": "499"
//   });

// TODO: This is not functional until it implements an error flow/handler on the backend.
axiosRetry(http, {
  retries: 3, // number of retries
  retryCondition: () => true, // retry no matter what
  retryDelay: (retryCount) => {
    // on every retry, we exponentially increase the delay
    const delay = 2 ** retryCount * 1000;
    // console.log(`retry delay: ${delay}`);
    return delay;
  },
});

export default http;
