import { createApp } from 'vue';
import { defaultTo } from 'lodash';
import { ConfigurationService } from '@nsftx/games-config';
import mockConfig from '@/mock/config';
import { queryStringParser, busService } from '@/utility';
import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';
import { sentry } from './utility';
import Retail from '@nsftx/seven-gravity-gateway/plugin-retail';
import types from './store/mutationTypes';

const app = createApp({
  extends: App,
});

if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
  sentry.start(app);
}

(async () => {
  window.addEventListener('message', (event) => {
    store.dispatch('handleParentMessages', event.data);
  });
  const queryParams = queryStringParser.parse(window.location.search) || {};
  const productId = queryParams.productId;
  const tenantId = queryParams.tenantId;
  // Get active product/game from query string parser
  const activeProduct = store.state.productList.includes(queryParams.productDisplayId)
    ? queryParams.productDisplayId
    : 'CrashCash';
  store.state.activeProductName = activeProduct; // 'CrashCash';
  // Set game config from active product/game
  store.state.gameConfig = mockConfig[activeProduct];
  const configService = new ConfigurationService({
    requiredPaths: [],
    environment: process.env.VUE_APP_ENVIRONMENT,
    applicationName: defaultTo(queryParams.application, 'Shop'),
    messageProtocol: defaultTo(queryParams.integrationType, 'gravityGateway'),
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          isDebug: true,
          slaveId: activeProduct,
          gameId: activeProduct,
          eventPropagation: {
            click: true,
            keydown: '*',
          },
          eventListeners: {
            keydown: ['9', '13', '49', '50', '51', '52', '54', '97', '100', '102', '106', '107', '111', '191'],
          },
          plugins: [new Retail()],
        },
      },
    ],
    channel: 'RETAIL',
    // CYPRESS ISSUES QUERY PARAMS CANNOT BE READ
    productName: activeProduct,
    productId: productId,
    ui: tenantId,
  });

  try {
    // Append config
    const config = await configService.getConfiguration();
    // eslint-disable-next-line no-console
    console.log(' Games Shop V2 Config Gateway ', config);
    store.commit(types.SET_USER_TOKEN, config.user?.token);
    store.state.config = config;
    /* await translations.fetch(
      store.getters.config.locale,
      store.getters.config.tenantId,
      'VSoccer', // TODO: set games translations
    ).then((response) => {
      store.dispatch('setTranslations', response);
      busService.init();
    }); */
    store.state.configServiceInit = true;
    const translations = (await i18n.common(config.locale)).default;
    store.dispatch('setTranslations', translations);
    await store.dispatch('setRules', config?.rules);
    await store.dispatch('updateOddsRules', config.rules);
    // Set min payin/stake from game config
    await store.dispatch('betslip/updateStake', defaultTo(config?.rules?.minBetAmount?.value, 1));
    if (store.state.loadApp && !store.state.busServiceInit) {
      busService.init();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(' Crash Config Error ', e);
  }
  // prettier-ignore
  app.use(router)
    .use(store)
    .mount('#app');
})();
